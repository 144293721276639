import { EmbedWallet, UserInfo, WalletEnvironment, WalletEvent } from '@cere/embed-wallet';
import { CereWalletSigner } from '@cere-ddc-sdk/ddc-client';
import { ethers } from 'ethers';
import { CereWalletCipher, JsonCipher } from '@cere-activity-sdk/ciphers';
import { createStatusResource } from './resources';
import { APP_ENV } from '../../constants';

export class CereWallet {
  readonly wallet: EmbedWallet;

  readonly signer: CereWalletSigner;

  private status: string | undefined;

  readonly cipher: JsonCipher;

  constructor() {
    this.wallet = new EmbedWallet({
      appId: 'ddc-web-console',
      env: (APP_ENV || 'stage') as WalletEnvironment,
    });

    this.cipher = new JsonCipher(new CereWalletCipher(this.wallet), ['$.event_type', '$.timestamp', '$.payload..*']);

    this.signer = new CereWalletSigner(this.wallet, { autoConnect: false });

    this.status = undefined;
  }

  getUserInfo(): Promise<UserInfo> {
    return this.wallet.getUserInfo();
  }

  getAddress() {
    return this.getProvider().getSigner().getAddress();
  }

  isReady() {
    return this.wallet.isReady.then(() => null);
  }

  async connect() {
    await this.signer.connect();
  }

  async init() {
    await this.wallet.init({
      appId: 'ddc-web-console',
      env: (APP_ENV || 'stage') as WalletEnvironment,
    });
    this.status = createStatusResource(this.wallet).getCurrentStatus();
    return this.status;
  }

  async disconnect() {
    await this.wallet.disconnect();
    this.status = undefined;
  }

  getProvider() {
    return new ethers.providers.Web3Provider(this.wallet.provider);
  }

  subscribe(event: WalletEvent, handler: (...args: any[]) => void) {
    return this.wallet.subscribe(event, handler);
  }

  async decrypt(json: string) {
    return this.cipher.decrypt(json);
  }

  encrypt(json: string) {
    return this.cipher.encrypt(json);
  }
}
