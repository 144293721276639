import { EmbedWallet, WalletStatus } from '@cere/embed-wallet';

export const createStatusResource = (wallet: EmbedWallet) => {
  let currentStatus: WalletStatus = 'not-ready';
  let listeners: Array<(status: WalletStatus) => void> = [];

  const subscribe = (listener: (status: WalletStatus) => void) => {
    listeners.push(listener);

    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  };

  const notify = (status: WalletStatus) => {
    currentStatus = status;
    listeners.forEach((listener) => listener(currentStatus));
  };

  const unsubscribeWallet = wallet.subscribe('status-update', notify);

  const getCurrentStatus = () => currentStatus;

  return {
    subscribe,
    getCurrentStatus,
    cleanup: () => {
      listeners = [];
      unsubscribeWallet();
    },
  };
};
