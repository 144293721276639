import { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { WalletStatus } from '@cere/embed-wallet';
import { AppContext } from './app-context';
import { AppContextType, isUserSession, Subscription, User, UserSession } from './types';
import { Loader } from './loader';
import { Tier } from '../../types';
import { delay } from '../../lib/delay';
import { CereWallet } from '../../lib/cere-wallet/cere-wallet';

export type AppProviderType = {
  children: ReactNode;
};

const USER_KEY = 'user';

enum AppStateType {
  initial = 1,
  ready = 2,
  updating = 4,
  failed = 3,
}

export function AppProvider({ children }: AppProviderType): ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const { ddcClient, davinciClient, eventService } = useContext(AppContext);
  const [token] = useState<string>('CERE');
  const [error, setAppError] = useState<string | undefined>();
  const [appState, setAppState] = useState<AppStateType>(AppStateType.initial);
  const [tiers, setTiers] = useState<Tier[]>([]);
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const cereWallet = useMemo(() => new CereWallet(), []);

  useEffect(() => {
    const initCereWallet = async () => {
      try {
        await cereWallet.init();
      } catch (err) {
        setAppError('Failed to initialize CereWallet');
      }
    };
    initCereWallet();
  }, [cereWallet]);

  useEffect(() => {
    cereWallet.subscribe('status-update', (status: WalletStatus, prevStatus: WalletStatus) => {
      if (prevStatus === 'connected' && status === 'ready') {
        setUser(null);
      }
    });
  }, [cereWallet]);

  const updateSubscription = useCallback(async (): Promise<void> => {
    setSubscription(null);
  }, []);

  const loadTiers = useCallback(async () => {
    setTiers([]);
  }, []);

  const saveUser = useCallback((userData: Omit<User, 'principal'> | null) => {
    if (userData == null) {
      setUser(null);
    } else {
      setUser({
        ...userData,
        principal: userData.address,
      });
    }
  }, []);

  const saveCereUser = useCallback(async () => {
    const userInfo = await cereWallet.getUserInfo();
    const accounts = await cereWallet.wallet.getAccounts();
    const ed25519Account = accounts.find((a) => a.type === 'ed25519');
    if (ed25519Account?.address) {
      saveUser({ name: userInfo?.name || userInfo.email, address: ed25519Account.address });
    }
  }, [cereWallet, saveUser]);

  const loadUser = useCallback(async (): Promise<UserSession | null> => {
    await cereWallet.isReady();
    if (cereWallet.wallet.status !== 'connected') {
      return null;
    }
    const userInfo = await cereWallet.getUserInfo();
    const accounts = await cereWallet.wallet.getAccounts();
    const ed25519Account = accounts.find((a) => a.type === 'ed25519');
    const userData = { name: userInfo?.name || userInfo.email, address: ed25519Account?.address || '' };
    if (ed25519Account?.address) {
      saveUser(userData);
    }
    return isUserSession(userData) ? userData : null;
  }, [cereWallet, saveUser]);

  const login = useCallback(async () => {
    try {
      await cereWallet.connect();

      await saveCereUser();
    } catch (e) {
      const errorMessage = (e as Error).message;
      if (errorMessage === 'User has closed the login modal') {
        // eslint-disable-next-line no-console
        console.warn('User has closed the login modal');
      } else {
        setAppError(errorMessage);
      }
    }
  }, [cereWallet, saveCereUser]);

  const logout = useCallback(() => {
    saveUser(null);
    cereWallet.disconnect();
  }, [cereWallet, saveUser]);

  const storageEventHandler = useCallback(
    (event) => {
      if (event.key === USER_KEY) {
        const userData = event.newValue;
        try {
          saveUser(JSON.parse(userData));
        } catch (e) {
          saveUser(null);
        }
      }
    },
    [saveUser],
  );

  useEffect(() => {
    window.addEventListener('storage', storageEventHandler);
    return () => window.removeEventListener('storage', storageEventHandler);
  }, [storageEventHandler]);

  useEffect(() => {
    setAppState((currentState) => {
      if (currentState === AppStateType.initial || currentState === AppStateType.updating) {
        return currentState;
      }
      return AppStateType.updating;
    });
    updateSubscription(user?.address)
      .then(() =>
        setAppState((currentState) => (currentState === AppStateType.updating ? AppStateType.ready : currentState)),
      )
      .catch(() => {
        setAppState(AppStateType.ready);
      });
  }, [updateSubscription, user?.address]);

  useEffect(() => {
    const getUser = async () => {
      const currentUser = await loadUser();
      let completed = false;
      setUser(
        currentUser && {
          ...currentUser,
          principal: currentUser.address,
        },
      );
      delay(120 * 1000).then(() => {
        if (!completed) {
          setAppError(
            "After 2 minutes of processing, the necessary data isn't loaded. Please, try to reload the page.",
          );
        }
      });
      Promise.resolve().then(() =>
        Promise.all([updateSubscription(currentUser?.address), loadTiers()])
          .then(() => setAppState(AppStateType.ready))
          .catch(() => {
            setAppState(AppStateType.ready);
          })
          .finally(() => {
            completed = true;
          }),
      );
    };
    getUser();
  }, [loadTiers, loadUser, updateSubscription]);

  const fullUser = useMemo(() => (user === null ? null : { ...user, subscription }), [subscription, user]);

  const onDecrypt = useCallback(
    (json: string) => {
      try {
        return cereWallet.decrypt(json);
      } catch (e) {
        setAppError((e as Error).message);
        return undefined;
      }
    },
    [cereWallet],
  );

  const onEncrypt = useCallback(
    (json: string) => {
      try {
        return cereWallet.encrypt(json);
      } catch (e) {
        setAppError((e as Error).message);
        return undefined;
      }
    },
    [cereWallet],
  );

  const context = useMemo(
    () =>
      ({
        login,
        logout,
        user: fullUser,
        updateSubscription,
        tiers,
        token,
        ddcClient,
        davinciClient,
        eventService,
        decrypt: onDecrypt,
        encrypt: onEncrypt,
      } as AppContextType),
    [
      login,
      logout,
      fullUser,
      updateSubscription,
      tiers,
      token,
      ddcClient,
      davinciClient,
      eventService,
      onDecrypt,
      onEncrypt,
    ],
  );

  return (
    <AppContext.Provider value={context}>
      <Loader error={error} completed={appState === AppStateType.ready}>
        {children}
      </Loader>
    </AppContext.Provider>
  );
}
