import { styled } from '@linaria/react';
import { NavLink } from 'react-router-dom';

export const NavMenuButton = styled.button`
  line-height: 3rem;
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 500;
  position: relative;
  font-size: 1.8rem;
  color: #fff;
  margin: 0 4.4rem 0 0;

  @screen md {
    margin: 0 0 0 4.4rem;
  }
`;

export const NavMenuLink = styled(NavLink)`
  line-height: 3rem;
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 500;
  position: relative;
  font-size: 1.8rem;
  color: #fff;
  margin: 0 4.4rem 0 0;

  @screen md {
    margin: 0 0 0 4.4rem;
  }

  &.active {
    pointer-events: none;
    color: var(--cyan);
  }

  &:not(.active):hover {
    color: var(--cyan);

    &:before {
      content: ' ';
      position: absolute;
      left: -1.3rem;
      top: 1.6rem;
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      background-color: currentColor;
      border-radius: 50%;
      animation: appear 300ms linear;
      transform-origin: center center;

      @keyframes appear {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    }
  }
`;

export const StyledTextBlock = styled.div`
  line-height: 3rem;
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 500;
  position: relative;
  font-size: 1.8rem;
  color: #fff;
  margin-right: 4.4rem;

  &.active {
    pointer-events: none;
    color: var(--cyan);
  }
`;
