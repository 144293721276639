import { isRecord } from '../../types';

type ApplicationPartitionNodeMetadata = {
  nodeId: string;
  nodeHttpAddress: string;
};

export type ApplicationPartition = {
  partitionId: string;
  active: boolean;
  sectorStart: number;
  sectorEnd: number;
  createdAt: string;
  master: ApplicationPartitionNodeMetadata;
  replicas: ApplicationPartitionNodeMetadata[];
};

export type ApplicationTopology = {
  appPubKey: string;
  partitions: ApplicationPartition[];
};

export type DdcRecordMetadata = {
  isEncrypted?: boolean;
};

export type DdcRecord = {
  id: string;
  data: string;
  timestamp: string;
  userPubKey: string;
  appPubKey: string;
  metadata?: DdcRecordMetadata;
};

export function isApplicationPartitionNodeMetadata(val: unknown): val is ApplicationPartitionNodeMetadata {
  return (
    isRecord(val) &&
    typeof val?.nodeId === 'string' &&
    val?.nodeId !== '' &&
    typeof val?.nodeHttpAddress === 'string' &&
    val?.nodeHttpAddress !== ''
  );
}

export function isApplicationPartition(val: unknown): val is ApplicationPartition {
  return (
    isRecord(val) &&
    typeof val?.partitionId === 'string' &&
    val.partitionId !== '' &&
    typeof val?.active === 'boolean' &&
    !Number.isNaN(Number(val?.sectorStart)) &&
    !Number.isNaN(Number(val?.sectorEnd)) &&
    typeof val?.createdAt === 'string' &&
    val.createdAt !== '' &&
    isApplicationPartitionNodeMetadata(val?.master)
  );
}

export function isApplicationTopology(val: unknown): val is ApplicationTopology {
  return (
    isRecord(val) &&
    typeof val?.appPubKey === 'string' &&
    val.appPubKey !== '' &&
    Array.isArray(val?.partitions) &&
    val.partitions.every((p) => isApplicationPartition(p))
  );
}

export type CidResponse = {
  cid: string;
};

export function isCidResponse(val: unknown): val is CidResponse {
  return isRecord(val) && typeof val.cid === 'string' && val.cid !== '';
}

const isDdcRecordMetadata = (val: unknown) =>
  isRecord(val) && (val.isEncrypted == null || typeof val.isEncrypted === 'boolean');

export const isDdcRecord = (val: unknown): val is DdcRecord =>
  isRecord(val) &&
  typeof val.id === 'string' &&
  val.id !== '' &&
  typeof val.data === 'string' &&
  val.data !== '' &&
  (val.metadata == null || isDdcRecordMetadata(val.metadata));

export const toDdcRecord = (val: unknown) =>
  !isRecord(val) ||
  typeof val.id !== 'string' ||
  typeof val.timestamp !== 'string' ||
  typeof val.account_id !== 'string' ||
  typeof val.app_id !== 'string'
    ? undefined
    : ({
        id: val.id,
        timestamp: val.timestamp,
        data: JSON.stringify(val),
        userPubKey: val.account_id,
        appPubKey: val.app_id,
        metadata: { isEncrypted: Object.prototype.hasOwnProperty.call(val, 'encryption') },
      } as DdcRecord);
