import { ReactElement } from 'react';
import { cx } from '@linaria/core';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { radial, radialBanner } from './app.styles';

import { Navigation } from './layout/navigation';
import { AppProvider } from './shared/provider';
import { Logout } from './routes/logout';
import { DataViewer } from './routes/viewer/data-viewer';
import { AssetViewer } from './routes/asset-viewer/asset-viewer';
import { Home } from './routes/home/home';

export function Main(): ReactElement {
  return (
    <AppProvider>
      <SWRConfig value={{ refreshWhenHidden: false, revalidateOnFocus: false }}>
        <HashRouter>
          <div className={cx(radial, radialBanner)} />
          <Navigation />
          <Switch>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/view">
              <DataViewer />
            </Route>
            <Route exact path="/view-assets">
              <AssetViewer />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </HashRouter>
      </SWRConfig>
    </AppProvider>
  );
}
