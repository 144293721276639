import { ReactElement, useContext } from 'react';
import { cx, css } from '@linaria/core';

import { NavMenuButton, NavMenuLink, StyledTextBlock } from './nav.styles';
import { Container } from './container';
import { AppContext } from '../shared/provider';

import logo from '../assets/img/logo-light.svg';

const spaceBetween = css`
  justify-content: space-between;
`;

export function Navigation(): ReactElement {
  const { user, login } = useContext(AppContext);

  return (
    <Container>
      <nav className="w-full bg-transparent text-white py-16 relative">
        <div id="navbarSupportedContent">
          <div className={cx('flex', spaceBetween)}>
            <div>
              <img src={logo} alt="logo" />
            </div>
            <ul className="flex flex-col md:flex-row justify-start md:justify-end">
              {!user && (
                <li>
                  <NavMenuLink exact to="/">
                    Home
                  </NavMenuLink>
                </li>
              )}
              {user && (
                <>
                  <li>
                    <StyledTextBlock>Welcome, {user.name}</StyledTextBlock>
                  </li>
                  <li>
                    <NavMenuLink exact to="/view">
                      Data viewer
                    </NavMenuLink>
                  </li>
                  <li>
                    <NavMenuLink exact to="/logout">
                      Logout
                    </NavMenuLink>
                  </li>
                </>
              )}
              {!user && (
                <li>
                  <NavMenuButton onClick={() => login()}>Sign In</NavMenuButton>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Container>
  );
}
